<template>
  <div class="container-md mt-md-4">
    <div class="section">
      <div class="row">
        <div class="col-md-5 d-none d-lg-block">
          <div class="row custom-height">
            <div
              class="col-6"
              style="padding-right: 0; height: 100%; overflow: hidden"
            >
              <div class="d-flex flex-column" style="height: 100%">
                <div class="section-wrapper-1">
                  <img
                    class="section-wrapper-1-image"
                    src="@/assets/icons/image-1.jpeg"
                    alt=""
                  />
                </div>

                <div class="section-wrapper-2">
                  <img
                    class="section-wrapper-2-image"
                    src="@/assets/icons/image-2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              class="col-6 d-flex align-items-end custom-padding"
              style="height: 100%; overflow: hidden"
            >
              <div class="section-wrapper-3">
                <img
                  class="section-wrapper-3-image"
                  src="@/assets/icons/image-3.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div
            class="row d-flex justify-content-end align-items-center"
            style="height: 100%"
          >
            <div class="col-md-10">
              <div class="section-right" :class="{ 'center-align': loading }">
                <div
                  v-if="loading && !loggedIn"
                  class="spinner-border"
                  role="status"
                  style=""
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <p v-if="loading && loggedIn" style="text-align: center">
                  You are logged in. <br />
                  Redirecting...
                </p>

                <div
                  v-if="!loading && form1['active']"
                  class="section-right-form"
                >
                  <div class="section-right-form-title">
                    Hello!
                    <p class="section-right-form-title-line"></p>
                  </div>
                  <p class="section-right-form-desc">
                    It is so great to see you here. Get started for a shift in
                    your career.
                  </p>
                  <div class="section-right-form-input">
                    <p class="section-right-form-input-title">Mobile Number*</p>
                    <input
                      type="number"
                      class="section-right-form-input-field"
                      :class="{
                        'section-right-form-input-field-warning':
                          $v.form1.data.identity.$error,
                      }"
                      placeholder="Enter"
                      v-model.trim="$v.form1.data.identity.$model"
                      @input="
                        form1.data.identity = $event.target.value.substr(0, 10)
                      "
                      v-on:keyup.enter="
                        $v.form1.data.identity.$error ? null : requestPhoneOTP()
                      "
                    />
                    <p
                      v-if="$v.form1.data.identity.$error"
                      class="section-right-form-input-warning"
                    >
                      Please enter a valid mobile number
                    </p>
                  </div>
                </div>
                <button
                  v-if="!loading && form1['active']"
                  class="section-right-button"
                  :class="{
                    'disabled-button':
                      !form1.data.identity || $v.form1.data.identity.$error,
                  }"
                  @click="requestPhoneOTP()"
                >
                  <div
                    v-if="form1.loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span v-else>{{ form1["buttonText"] }}</span>
                </button>

                <div
                  v-if="!loading && form2['active']"
                  class="section-right-form"
                >
                  <div class="section-right-form-title">
                    Verify
                    <p class="section-right-form-title-line"></p>
                  </div>
                  <p class="section-right-form-desc">
                    We have sent an OTP to your registered mobile number for the
                    purpose of verification.
                  </p>
                  <div class="section-right-form-input">
                    <p class="section-right-form-input-title">Enter OTP*</p>
                    <div class="d-flex" style="margin-top: 10px">
                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form2.data.number1.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="phone-otp-1"
                        @input="
                          checkInput(
                            parseInt($event.target.value),
                            'phone-otp-2'
                          );
                          form2.data.number1 = $event.target.value.substr(0, 1);
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? (form2.data.number1 = null)
                            : null
                        "
                        v-model.trim="$v.form2.data.number1.$model"
                      />

                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form2.data.number2.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="phone-otp-2"
                        @input="
                          checkInput(
                            parseInt($event.target.value),
                            'phone-otp-3'
                          );
                          form2.data.number2 = $event.target.value.substr(0, 1);
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? ((form2.data.number2 = null),
                              focusElement('phone-otp-1'))
                            : null
                        "
                        v-model.trim="$v.form2.data.number2.$model"
                      />
                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form2.data.number3.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="phone-otp-3"
                        @input="
                          checkInput(
                            parseInt($event.target.value),
                            'phone-otp-4'
                          );
                          form2.data.number3 = $event.target.value.substr(0, 1);
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? ((form2.data.number3 = null),
                              focusElement('phone-otp-2'))
                            : null
                        "
                        v-model.trim="$v.form2.data.number3.$model"
                      />
                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form2.data.number4.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="phone-otp-4"
                        v-model.trim="$v.form2.data.number4.$model"
                        @input="
                          form2.data.number4 = $event.target.value.substr(0, 1)
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? ((form2.data.number4 = null),
                              focusElement('phone-otp-3'))
                            : null
                        "
                        v-on:keyup.enter="
                          $v.form2.data.number1.$error ||
                          $v.form2.data.number2.$error ||
                          $v.form2.data.number3.$error ||
                          $v.form2.data.number4.$error
                            ? null
                            : verifyPhoneOTP()
                        "
                      />
                    </div>
                  </div>
                  <p class="section-right-form-text-button d-flex">
                    <span
                      :class="{ 'disabled-button': !form2.resendOTP }"
                      style="cursor: pointer"
                      @click="
                        form2.resendOTP = false;
                        requestPhoneOTP();
                      "
                      >Resend OTP?</span
                    >
                    <Counter
                      v-if="!form2.resendOTP"
                      :key="'counter-' + form2.resendOTP"
                      :seconds="45"
                      @counterFinish="form2.resendOTP = true"
                    />
                  </p>
                  <div
                    v-if="showWhatsappOptin && cSource == 'flywheel'"
                    class="d-flex align-items-center"
                    style="gap: 8px; margin-bottom: 14px"
                  >
                    <i
                      v-if="whatsappOptinChecked"
                      class="bi bi-check-square-fill checkb"
                      style="color: #01b9f5"
                      @click="whatsappOptinChecked = false"
                    ></i>
                    <i
                      v-else
                      class="bi bi-square checkb"
                      style="color: #ccc"
                      @click="whatsappOptinChecked = true"
                    ></i>
                    <label for="whatsapp-checkbox" class="customcheck-label">
                      Enable Whatsapp Notifications
                    </label>
                  </div>
                  <p class="section-right-form-desc2">
                    By logging in, I agree to
                    <a href="https://goodcreator.co/terms" target="_blank"
                      >Terms & Conditions</a
                    >
                    and
                    <a
                      href="https://goodcreator.co/privacy-policy"
                      target="_blank"
                      >Privacy Policy</a
                    >
                  </p>
                </div>
                <button
                  v-if="!loading && form2['active']"
                  class="section-right-button"
                  :class="{
                    'disabled-button':
                      !form2.data.number1 ||
                      !form2.data.number2 ||
                      !form2.data.number3 ||
                      !form2.data.number4 ||
                      $v.form2.data.number1.$error ||
                      $v.form2.data.number2.$error ||
                      $v.form2.data.number3.$error ||
                      $v.form2.data.number4.$error,
                  }"
                  @click="verifyPhoneOTP()"
                >
                  <div
                    v-if="form2.loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span v-else>{{ form2["buttonText"] }}</span>
                </button>

                <div
                  v-if="!loading && form3['active']"
                  class="section-right-form"
                >
                  <div class="section-right-form-title">
                    Get Started
                    <p class="section-right-form-title-line"></p>
                  </div>
                  <p class="section-right-form-desc">
                    Please enter the following details and verify your email
                    address to get started!
                  </p>
                  <div class="section-right-form-input">
                    <p class="section-right-form-input-title">Name*</p>
                    <input
                      type="text"
                      class="section-right-form-input-field"
                      placeholder="Enter your name"
                      v-model="form3.data.name"
                    />
                    <p class="section-right-form-input-warning">
                      <span v-if="$v.form3.data.name.$error"
                        >Please enter a valid name</span
                      >
                    </p>
                  </div>
                  <div class="section-right-form-input">
                    <p class="section-right-form-input-title">Email address*</p>
                    <input
                      :class="{
                        'section-right-form-input-field-warning':
                          $v.form3.data.email.$error,
                      }"
                      type="text"
                      class="section-right-form-input-field"
                      placeholder="Enter your email address"
                      v-model.trim="$v.form3.data.email.$model"
                      v-on:keyup.enter="
                        $v.form3.data.email.$error
                          ? null
                          : form3.emailVerificationNeeded
                          ? requestEmailOTP()
                          : login()
                      "
                    />
                    <p
                      v-if="$v.form3.data.email.$error"
                      class="section-right-form-input-warning"
                    >
                      Please enter a valid email address
                    </p>
                  </div>
                </div>
                <button
                  v-if="!loading && form3['active']"
                  class="section-right-button"
                  :class="{
                    'disabled-button':
                      !form3.data.name ||
                      !form3.data.email ||
                      $v.form3.data.name.$error ||
                      $v.form3.data.email.$error,
                  }"
                  @click="
                    form3.emailVerificationNeeded ? requestEmailOTP() : login()
                  "
                >
                  <div
                    v-if="form3.loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span v-else>{{ form3["buttonText"] }}</span>
                </button>

                <div
                  v-if="!loading && form4['active']"
                  class="section-right-form"
                >
                  <div class="section-right-form-title">
                    Verify
                    <p class="section-right-form-title-line"></p>
                  </div>
                  <p class="section-right-form-desc">
                    We have sent an OTP to your registered email address for the
                    purpose of verification.
                  </p>
                  <div class="section-right-form-input">
                    <p class="section-right-form-input-title">Enter OTP*</p>
                    <div class="d-flex" style="margin-top: 10px">
                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form4.data.number1.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="email-otp-1"
                        @input="
                          checkInput(
                            parseInt($event.target.value),
                            'email-otp-2'
                          );
                          form4.data.number1 = $event.target.value.substr(0, 1);
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? (form4.data.number1 = null)
                            : null
                        "
                        v-model.trim="$v.form4.data.number1.$model"
                      />
                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form4.data.number2.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="email-otp-2"
                        @input="
                          checkInput(
                            parseInt($event.target.value),
                            'email-otp-3'
                          );
                          form4.data.number2 = $event.target.value.substr(0, 1);
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? ((form4.data.number2 = null),
                              focusElement('email-otp-1'))
                            : null
                        "
                        v-model.trim="$v.form4.data.number2.$model"
                      />
                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form4.data.number3.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="email-otp-3"
                        @input="
                          checkInput(
                            parseInt($event.target.value),
                            'email-otp-4'
                          );
                          form4.data.number3 = $event.target.value.substr(0, 1);
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? ((form4.data.number3 = null),
                              focusElement('email-otp-2'))
                            : null
                        "
                        v-model.trim="$v.form4.data.number3.$model"
                      />
                      <input
                        type="number"
                        class="section-right-form-input-field-square"
                        :class="{
                          'section-right-form-input-field-square-warning':
                            $v.form4.data.number4.$error,
                        }"
                        maxlength="1"
                        pattern="[0-9]{1}"
                        inputmode="numeric"
                        id="email-otp-4"
                        v-model.trim="$v.form4.data.number4.$model"
                        @input="
                          form4.data.number4 = $event.target.value.substr(0, 1)
                        "
                        @keyup="
                          $event.key === 'Backspace' || $event.key === 'Delete'
                            ? ((form4.data.number4 = null),
                              focusElement('email-otp-3'))
                            : null
                        "
                        v-on:keyup.enter="
                          $v.form4.data.number1.$error ||
                          $v.form4.data.number2.$error ||
                          $v.form4.data.number3.$error ||
                          $v.form4.data.number4.$error
                            ? null
                            : verifyEmailOTP()
                        "
                      />
                    </div>
                  </div>
                  <p class="section-right-form-text-button d-flex">
                    <span
                      :class="{ 'disabled-button': !form4.resendOTP }"
                      style="cursor: pointer"
                      @click="
                        form4.resendOTP = false;
                        requestEmailOTP();
                      "
                      >Resend OTP?</span
                    >
                    <Counter
                      v-if="!form4.resendOTP"
                      :key="'counter-' + form4.resendOTP"
                      :seconds="45"
                      @counterFinish="form4.resendOTP = true"
                    />
                  </p>
                  <p class="section-right-form-desc2">
                    By logging in, I agree to
                    <a href="https://goodcreator.co/terms" target="_blank"
                      >Terms & Conditions</a
                    >
                    and
                    <a
                      href="https://goodcreator.co/privacy-policy"
                      target="_blank"
                      >Privacy Policy</a
                    >
                  </p>
                </div>
                <button
                  v-if="!loading && form4['active']"
                  class="section-right-button"
                  :class="{
                    'disabled-button':
                      !form4.data.number1 ||
                      !form4.data.number2 ||
                      !form4.data.number3 ||
                      !form4.data.number4 ||
                      $v.form4.data.number1.$error ||
                      $v.form4.data.number2.$error ||
                      $v.form4.data.number3.$error ||
                      $v.form4.data.number4.$error,
                  }"
                  @click="verifyEmailOTP()"
                >
                  <div
                    v-if="form4.loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span v-else>{{ form4["buttonText"] }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import gql from "graphql-tag";
import Counter from "@/components/Counter";
import webengage from "../mixins/webengage";

const clientSecret = "6wc4KAzv/JfaTAKSTgCbS+5cNbcOY26HHtXJd+yO";

export default {
  name: "IndexPage",
  components: {
    Counter,
  },
  data() {
    return {
      loggedIn: false,
      loading: false,
      showWhatsappOptin: false,
      whatsappOptinChecked: true,
      cSource: null,
      form1: {
        active: false,
        data: {
          identity: null,
          requestId: null,
          operation: "LOGIN",
          targetMode: "PHONE",
          operationNeeded: null,
        },
        buttonText: "Generate OTP",
        loading: false,
      },
      form2: {
        active: false,
        data: {
          secret: null,
          number1: null,
          number2: null,
          number3: null,
          number4: null,
        },
        buttonText: "Verify Number",
        loading: false,
        resendOTP: false,
      },
      form3: {
        active: false,
        data: {
          name: null,
          email: null,
        },
        buttonText: "Get started",
        loading: false,
        emailVerificationNeeded: false,
      },
      form4: {
        active: false,
        data: {
          identity: null,
          requestId: null,
          operation: "LINK",
          targetMode: "EMAIL",
          operationNeeded: null,
          secret: null,
          number1: null,
          number2: null,
          number3: null,
          number4: null,
        },
        buttonText: "Verify Email",
        loading: false,
        resendOTP: false,
      },
      form5: {
        active: false,
        data: {
          identity: null,
          requestId: null,
          operation: "LINK",
          targetMode: "EMAIL",
        },
        buttonText: "Generate OTP",
        loading: false,
      },
      redirectUrl: null,
      clientId: null,
      portalId: null,
      authcode: null,
    };
  },
  validations: {
    form1: {
      data: {
        identity: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
      },
    },
    form2: {
      data: {
        number1: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        number2: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        number3: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        number4: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
      },
    },
    form3: {
      data: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    },
    form4: {
      data: {
        number1: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        number2: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        number3: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
        number4: {
          required,
          numeric,
          minLength: minLength(1),
          maxLength: maxLength(1),
        },
      },
    },
    form5: {
      data: {
        identity: {
          required,
          email,
        },
      },
    },
  },
  created() {
    this.redirectUrl = decodeURIComponent(this.$route.query.returnurl);
    this.clientId = this.$route.query.clientId;
    this.portalId = this.$route.query.portalId;
    this.authcode = this.$route.query.authcode;
    this.cSource = this.$route.query.cSource;
    this.validateRedirectUrl(this.redirectUrl);

    if (this.redirectUrl) {
      if (this.portalId === "CREATOR") {
        this.creatorFunction();
      } else {
        this.ssoDefaultFunction();
      }
    }
  },
  methods: {
    makeToast(title, desc) {
      this.$bvToast.toast(desc, {
        title,
        variant: "danger",
        solid: true,
      });
    },
    ssoDefaultFunction() {
      if (this.$route.query.updateToken == "true") {
        this.form4.data.requestId = this.$route.query.requestId;
        this.form3.data.email = this.$route.query.email;
        this.form1.data.operationNeeded = this.$route.query.operationNeeded;
        this.form3.data.name =
          this.$route.query.name != null && this.$route.query.name != "null"
            ? decodeURIComponent(this.$route.query.name)
            : null;
        this.form3.active = true;
        this.$router.replace({
          query: {
            returnurl: this.redirectUrl,
            clientId: this.clientId,
            portalId: this.portalId,
          },
        });
        this.getThirdPartyAccessToken();
      } else if (this.$cookies.isKey("user")) {
        this.loading = true;
        const userToken = this.$cookies.get("user")["userToken"];
        const refreshToken = this.$cookies.get("user")["refreshToken"];
        this.apolloInitDevice(clientSecret, userToken, refreshToken);
      } else if (!window.sessionStorage.getItem("userToken")) {
        this.apolloInitDeviceWithutAuth(clientSecret);
      } else this.form1.active = true;
    },
    creatorFunction() {
      if (this.$cookies.isKey("creatorUser")) {
        this.loading = true;
        const userToken = this.$cookies.get("creatorUser")["userToken"];
        const refreshToken = this.$cookies.get("creatorUser")["refreshToken"];
        this.apolloInitDeviceV2(clientSecret, userToken, refreshToken);
      } else if (this.authcode) {
        this.callLoginV2(this.authcode, "BULBUL_SSO");
      } else {
        this.form1.active = true;
        if (this.cSource == "flywheel") {
          this.WebLoginViewed();
        }
      }
    },
    apolloInitDevice(clientSecret, userToken, userRefreshToken) {
      try {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation initDeviceV2Mutation($input: InitDeviceInput!) {
                initDeviceV2(input: $input) {
                  userToken
                  refreshTime
                  userRefreshToken
                  loggedIn
                }
              }
            `,
            // Parameters
            variables: {
              input: {
                clientSecret: clientSecret,
                userToken: userToken,
                userRefreshToken: userRefreshToken,
              },
            },
          })
          .then((response) => {
            //Result
            if (response.data.initDeviceV2.loggedIn) {
              this.getThirdPartyAccessToken();
            } else {
              this.clearCookies();
              this.$router.go();
            }
          })
          .catch(() => {
            console.log("error");
          });
      } catch {
        console.log("error");
      }
    },
    apolloInitDeviceV2(clientSecret, userToken, userRefreshToken) {
      try {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation initDeviceV2Mutation($input: InitDeviceInput!) {
                initDeviceV2(input: $input) {
                  userToken
                  refreshTime
                  userRefreshToken
                  loggedIn
                }
              }
            `,
            // Parameters
            variables: {
              input: {
                clientSecret: clientSecret,
                userToken: userToken,
                userRefreshToken: userRefreshToken,
              },
            },
          })
          .then((response) => {
            //Result
            if (response.data.initDeviceV2.loggedIn) {
              if (
                this.redirectUrl &&
                this.redirectUrl != undefined &&
                this.redirectUrl != "undefined"
              ) {
                if (this.cSource !== "flywheel") {
                  window.open(this.redirectUrl, "_self");
                  this.loading = false;
                }
              }
            } else {
              this.clearGenericCookies("creatorUser");
              this.$router.go();
            }
          })
          .catch(() => {
            console.log("error");
            this.clearGenericCookies("creatorUser");
            this.$router.go();
          });
      } catch {
        console.log("error");
      }
    },
    apolloInitDeviceWithutAuth(clientSecret) {
      try {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation initDeviceV2Mutation($input: InitDeviceInput!) {
                initDeviceV2(input: $input) {
                  userToken
                  refreshTime
                  userRefreshToken
                  loggedIn
                }
              }
            `,
            // Parameters
            variables: {
              input: {
                clientSecret: clientSecret,
                userToken: null,
                userRefreshToken: null,
              },
            },
          })
          .then((response) => {
            //Result
            if (response.data.initDeviceV2) {
              let finalData = response.data.initDeviceV2;
              // let anonymousUser = {
              //   userToken: finalData.userToken,
              //   refreshToken: finalData.userRefreshToken,
              // };
              window.sessionStorage.setItem("userToken", finalData.userToken);
              window.sessionStorage.setItem(
                "refreshToken",
                finalData.userRefreshToken
              );
              // this.setAnonymousCookies(anonymousUser);
              this.$router.go();
            }
          })
          .catch(() => {
            console.log("error");
          });
      } catch {
        console.log("error");
      }
    },
    setAnonymousCookies(data) {
      // check for domain
      let SUBDOMAIN = "goodcreator.co";
      if (window.location.hostname.match("winkl")) SUBDOMAIN = "winkl.co";
      if (window.location.hostname.match("localhost")) SUBDOMAIN = null;
      this.$cookies.set("anonymousUser", data, "1d", null, SUBDOMAIN);
    },
    setCookies(data) {
      // check for domain
      let SUBDOMAIN = "goodcreator.co";
      if (window.location.hostname.match("localhost")) SUBDOMAIN = null;

      this.$cookies.set("user", data, "25d", null, SUBDOMAIN);
    },
    clearCookies() {
      let SUBDOMAIN = "goodcreator.co";
      if (window.location.hostname.match("localhost")) SUBDOMAIN = null;

      this.$cookies.remove("user", null, SUBDOMAIN);
      this.$cookies.remove("user", null, "." + SUBDOMAIN);
    },
    setGenericCookies(name, data) {
      // check for domain
      let SUBDOMAIN = "goodcreator.co";
      if (window.location.hostname.match("localhost")) SUBDOMAIN = null;

      this.$cookies.set(name, data, "25d", null, SUBDOMAIN);
    },
    clearGenericCookies(name) {
      let SUBDOMAIN = "goodcreator.co";
      if (window.location.hostname.match("localhost")) SUBDOMAIN = null;

      this.$cookies.remove(name, null, SUBDOMAIN);
      this.$cookies.remove(name, null, "." + SUBDOMAIN);
    },
    requestPhoneOTP() {
      this.$v.form1.$touch();

      if (this.$v.form1.$invalid) {
        this.makeToast("Warning", "Please fill all the required field!");
      } else {
        this.form1.loading = true;
        try {
          this.$apollo
            .mutate({
              // Query
              mutation: gql`
                mutation requestOTP($input: OTPRequestInput!) {
                  requestOTP(input: $input) {
                    status
                    requestId
                    operation
                    whatsappOptin
                  }
                }
              `,
              // Parameters
              variables: {
                input: {
                  operation: this.form1.data.operation,
                  targetMode: this.form1.data.targetMode,
                  identity: this.form1.data.identity,
                  countryCode: "+91",
                },
              },
            })
            .then((response) => {
              this.form1.loading = false;
              //Result
              if (
                response.data &&
                response.data.requestOTP.status == "SUCCESS"
              ) {
                this.form1.data.operationNeeded =
                  response.data.requestOTP.operation;
                this.form1.data.requestId = response.data.requestOTP.requestId;

                if (
                  response.data.requestOTP.whatsappOptin == true ||
                  response.data.requestOTP.whatsappOptin == false
                ) {
                  this.showWhatsappOptin = false;
                }
                if (!(this.cSource == "flywheel")) {
                  this.showWhatsappOptin = false;
                }

                this.form1.active = false;
                this.form2.active = true;
                if (this.cSource == "flywheel") this.WebEnterOTP();
                setTimeout(() => {
                  document.getElementById("phone-otp-1").focus();
                }, 100);
              }
            })
            .catch((error) => {
              this.form1.loading = false;
              if (error.graphQLErrors[0]) {
                this.makeToast("Error", error.graphQLErrors[0]["message"]);
              } else
                this.makeToast(
                  "Error",
                  "Something went wrong! Please try again"
                );
            });
        } catch {
          this.makeToast("Error", "Something went wrong! Please try again");
          this.form1.loading = false;
        }
      }
    },
    verifyPhoneOTP() {
      this.$v.form2.$touch();
      this.loading = true;
      if (this.$v.form2.$invalid) {
        this.makeToast("Warning", "Please fill all the required field!");
      } else {
        this.form2.loading = true;
        this.form2.data.secret =
          this.form2.data.number1 +
          this.form2.data.number2 +
          this.form2.data.number3 +
          this.form2.data.number4;
        try {
          let inputQuery = {
            mode: "PHONE",
            id: this.form1.data.requestId,
            secret: this.form2.data.secret,
          };

          if (this.showWhatsappOptin && this.cSource == "flywheel") {
            inputQuery.whatsappOptIn = this.whatsappOptinChecked;
          }

          this.$apollo
            .mutate({
              // Query
              mutation: gql`
                mutation LoginV2($input: UserAuthInputV2!) {
                  loginV2(input: $input) {
                    status
                    token
                    refreshToken
                    bbDeviceId
                    userAccount {
                      id
                      email
                      userId
                      name
                      webengageUserId
                      g3MemberId
                      profileImage {
                        url
                      }
                      ... on HostAccount {
                        completionPercent
                      }
                    }
                  }
                }
              `,
              // Parameters
              variables: {
                input: {
                  ...inputQuery,
                },
              },
            })
            .then((response) => {
              //Result
              if (response.data && response.data.loginV2.status == "SUCCESS") {
                const userToken = response.data.loginV2.token;
                const refreshToken = response.data.loginV2.refreshToken;
                const operationNeeded = this.form1.data.operationNeeded;
                const params = new URLSearchParams(location.search);

                if (this.portalId === "CREATOR") {
                  this.setGenericCookies("creatorUser", {
                    userToken: userToken,
                    refreshToken: refreshToken,
                    userAccount:
                      response.data.loginV2 &&
                      response.data.loginV2.userAccount,
                    profilePicURL:
                      response.data.loginV2 &&
                      response.data.loginV2.userAccount &&
                      response.data.loginV2.userAccount.profileImage &&
                      response.data.loginV2.userAccount.profileImage.url,
                    name:
                      response.data.loginV2 &&
                      response.data.loginV2.userAccount &&
                      response.data.loginV2.userAccount.name,
                  });
                  if (
                    this.cSource == "flywheel" &&
                    response.data.loginV2 &&
                    response.data.loginV2.userAccount &&
                    response.data.loginV2.userAccount.webengageUserId
                  ) {
                    this.webengageLogin(
                      response.data.loginV2.userAccount.webengageUserId
                    );
                  }
                  // if (
                  //   this.redirectUrl &&
                  //   this.redirectUrl != undefined &&
                  //   this.redirectUrl != "undefined"
                  // ) {
                  //   if (this.cSource !== "flywheel") {
                  //     console.log("reach2");
                  //     window.open(this.redirectUrl, "_self");
                  //     this.loading = false;
                  //   }
                  //   console.log("reach3");
                  // }
                } else {
                  if (operationNeeded === "SIGNUP") {
                    this.WebSignUp({
                      source: params && params.get("source"),
                      userID: response.data.loginV2.userAccount.webengageUserId,
                      g3MemberId: response.data.loginV2.userAccount.g3MemberId,
                      entity:
                        params &&
                        params.get("returnurl") &&
                        params
                          .get("returnurl")
                          .indexOf("www.goodcreatoracademy.com")
                          ? "Good Creator Academy"
                          : "",
                      phone: this.form1.data.identity
                        ? `+91${this.form1.data.identity}`
                        : null,
                    });
                  } else if (operationNeeded === "LOGIN") {
                    this.WebLogin({
                      source: params && params.get("source"),
                      userID: response.data.loginV2.userAccount.webengageUserId,
                      g3MemberId: response.data.loginV2.userAccount.g3MemberId,
                      entity:
                        params &&
                        params.get("returnurl") &&
                        params
                          .get("returnurl")
                          .indexOf("www.goodcreatoracademy.com")
                          ? "Good Creator Academy"
                          : "",
                    });
                  }
                  this.setCookies({
                    userToken: userToken,
                    refreshToken: refreshToken,
                  });
                  this.$router.replace({
                    query: {
                      updateToken: "true",
                      returnurl: this.redirectUrl,
                      clientId: this.clientId,
                      portalId: this.portalId,
                      operationNeeded: this.form1.data.operationNeeded,
                      name: response.data.loginV2.userAccount.name,
                    },
                  });

                  setTimeout(() => {
                    this.loading = false;
                    this.form2.loading = false;
                    this.$router.go();
                  }, 3000);
                }
              }
            })
            .catch((error) => {
              this.loading = false;

              this.form2.loading = false;
              if (error.graphQLErrors && error.graphQLErrors[0]) {
                this.makeToast("Error", error.graphQLErrors[0]["message"]);
              } else
                this.makeToast(
                  "Error",
                  "Something went wrong! Please try again"
                );
            });
        } catch {
          this.loading = false;

          this.form2.loading = false;
          this.makeToast("Error", "Something went wrong! Please try again");
        }
      }
    },
    callLoginV2(secret, mode) {
      try {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation LoginV2($input: UserAuthInputV2!) {
                loginV2(input: $input) {
                  status
                  token
                  refreshToken
                  bbDeviceId
                  userAccount {
                    id
                    email
                    userId
                    name
                    webengageUserId
                    g3MemberId
                    profileImage {
                      url
                    }
                  }
                }
              }
            `,
            // Parameters
            variables: {
              input: {
                mode,
                id: secret,
              },
            },
          })
          .then((response) => {
            //Result
            if (response.data && response.data.loginV2.status == "SUCCESS") {
              const userToken = response.data.loginV2.token;
              const refreshToken = response.data.loginV2.refreshToken;

              this.setGenericCookies("creatorUser", {
                userToken: userToken,
                refreshToken: refreshToken,
                userAccount:
                  response.data.loginV2 && response.data.loginV2.userAccount,
              });
              // if (
              //   this.redirectUrl &&
              //   this.redirectUrl != undefined &&
              //   this.redirectUrl != "undefined"
              // ) {
              //   if (this.cSource !== "flywheel") {
              //     window.open(this.redirectUrl, "_self");
              //     this.loading = false;
              //   }
              // }
            } else {
              this.makeToast("Error", "Something went wrong! Please try again");
            }
          })
          .catch((error) => {
            this.loading = false;
            this.form2.loading = false;
            console.log({ error });
            this.makeToast("Error", "Please login to continue.");

            // If we want to redirect in case of api failed

            // if (
            //   this.redirectUrl &&
            //   this.redirectUrl != undefined &&
            //   this.redirectUrl != "undefined"
            // ) {
            //   window.open(this.redirectUrl, "_self");
            //   this.loading = false;
            // } else {
            //   window.open("https://www.goodcreatoracademy.com/", "_self");
            // }

            // If we want to login by phone in case of api failed

            this.form1.active = true;
          });
      } catch {
        this.loading = false;

        this.form2.loading = false;
        this.makeToast("Error", "Something went wrong! Please try again");
      }
    },
    requestEmailOTP() {
      this.$v.form3.$touch();

      if (this.$v.form3.$invalid) {
        this.makeToast("Warning", "Please fill all the required field!");
      } else {
        this.form3.loading = true;
        try {
          this.$apollo
            .mutate({
              // Query
              mutation: gql`
                mutation requestOTP($input: OTPRequestInput!) {
                  requestOTP(input: $input) {
                    status
                    requestId
                    operation
                  }
                }
              `,
              // Parameters
              variables: {
                input: {
                  operation: "LINK",
                  targetMode: "EMAIL",
                  identity: this.form3.data.email,
                  countryCode: "",
                },
              },
            })
            .then((response) => {
              this.form3.loading = false;
              this.loading = false;
              //Result
              if (
                response.data &&
                response.data.requestOTP.status == "SUCCESS"
              ) {
                this.form4.data.requestId = response.data.requestOTP.requestId;
                this.form1.active = false;
                this.form2.active = false;
                this.form3.active = false;
                this.form4.active = true;
                setTimeout(() => {
                  document.getElementById("email-otp-1").focus();
                }, 100);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.form3.loading = false;
              if (error.graphQLErrors[0]) {
                this.makeToast("Error", error.graphQLErrors[0]["message"]);
              } else
                this.makeToast(
                  "Error",
                  "Something went wrong! Please try again"
                );
            });
        } catch {
          this.loading = false;
          this.form3.loading = false;
          this.makeToast("Error", "Something went wrong! Please try again");
        }
      }
    },
    verifyEmailOTP() {
      this.$v.form4.$touch();

      if (this.$v.form4.$invalid) {
        this.makeToast("Warning", "Please fill all the required field!");
      } else {
        this.form4.loading = true;
        this.form4.data.secret =
          this.form4.data.number1 +
          this.form4.data.number2 +
          this.form4.data.number3 +
          this.form4.data.number4;
        try {
          this.$apollo
            .mutate({
              // Query
              mutation: gql`
                mutation LinkUserEmailViaOTP($input: EmailLinkInput!) {
                  LinkUserEmailViaOTP(input: $input) {
                    id
                    email
                    status
                  }
                }
              `,
              // Parameters
              variables: {
                input: {
                  id: this.form4.data.requestId,
                  secret: this.form4.data.secret,
                },
              },
            })
            .then((response) => {
              this.form4.loading = false;
              //Result
              if (response.data && response.data.LinkUserEmailViaOTP.id) {
                this.getThirdPartyAccessToken();
                // this.SetUserDetails({
                //   email: this.form3.data.email,
                //   name: this.form3.data.name,
                // });
              }
            })
            .catch((error) => {
              this.form4.loading = false;
              if (error.graphQLErrors[0]) {
                this.makeToast("Error", error.graphQLErrors[0]["message"]);
              } else
                this.makeToast(
                  "Error",
                  "Something went wrong! Please try again"
                );
            });
        } catch {
          this.form4.loading = false;
          this.makeToast("Error", "Something went wrong! Please try again");
        }
      }
    },
    checkPhoneOTP() {
      this.$v.form2.$touch();

      if (this.$v.form2.$invalid) {
        this.makeToast("Warning", "Please fill all the required field!");
      } else {
        this.form2.active = false;
        this.form3.active = true;
      }
    },
    login() {
      this.$v.form3.$touch();

      if (this.$v.form3.$invalid) {
        this.makeToast("Warning", "Please fill all the required field!");
      } else {
        this.form2.data.secret =
          this.form2.data.number1 +
          this.form2.data.number2 +
          this.form2.data.number3 +
          this.form2.data.number4;
        this.form3.loading = true;
        try {
          this.$apollo
            .mutate({
              // Query
              mutation: gql`
                mutation LoginV2($input: UserAuthInputV2!) {
                  loginV2(input: $input) {
                    status
                    token
                    refreshToken
                    bbDeviceId
                    userAccount {
                      id
                      email
                    }
                  }
                }
              `,
              // Parameters
              variables: {
                input: {
                  mode: "PHONE",
                  id: this.form1.data.requestId,
                  secret: this.form2.data.secret,
                  name: this.form3.data.name,
                  email: this.form3.data.email,
                },
              },
            })
            .then((response) => {
              this.form3.loading = false;
              //Result
              if (response.data && response.data.loginV2.status == "SUCCESS") {
                const userToken = response.data.loginV2.token;
                const refreshToken = response.data.loginV2.refreshToken;
                this.setCookies({
                  userToken: userToken,
                  refreshToken: refreshToken,
                });
                this.$router.replace({
                  query: {
                    updateToken: "true",
                    returnurl: this.redirectUrl,
                    clientId: this.clientId,
                    portalId: this.portalId,
                    requestId: this.form1.data.requestId,
                    email: this.form3.data.email,
                  },
                });
                this.$router.go();
              }
            })
            .catch((error) => {
              this.form3.loading = false;
              if (error.graphQLErrors[0]) {
                if (error.graphQLErrors[0]["message"].match("Invalid OTP")) {
                  this.form3.active = false;
                  this.form2.active = true;
                }
                this.makeToast("Error", error.graphQLErrors[0]["message"]);
              } else
                this.makeToast(
                  "Error",
                  "Something went wrong! Please try again"
                );
            });
        } catch {
          this.form3.loading = false;
          this.makeToast("Error", "Something went wrong! Please try again");
        }
      }
    },
    getThirdPartyAccessToken() {
      this.loading = true;
      try {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation GetThirdPartyAccessToken(
                $input: ThirdPartyAccessTokenInput!
              ) {
                getThirdPartyAccessToken(input: $input) {
                  token
                  status
                  emailVerificationNeeded
                  portalId
                }
              }
            `,
            // Parameters
            variables: {
              input: {
                portalId: "GRAPHY",
              },
            },
          })
          .then((response) => {
            //Result
            if (
              response.data &&
              response.data.getThirdPartyAccessToken.emailVerificationNeeded ==
                true
            ) {
              this.form3.emailVerificationNeeded = true;
              if (this.form3.data.email) {
                this.requestEmailOTP();
              } else {
                this.form1.active = false;
                this.form2.active = false;
                this.form3.active = true;
                this.form4.active = false;
                this.loading = false;
              }
            } else if (
              response.data &&
              response.data.getThirdPartyAccessToken.emailVerificationNeeded ==
                false
            ) {
              this.loggedIn = true;
              this.form1.active = true;
              this.form2.active = false;
              this.form3.active = false;
              this.form4.active = false;

              if (
                this.redirectUrl &&
                this.redirectUrl != undefined &&
                this.redirectUrl != "undefined"
              ) {
                window.open(
                  this.redirectUrl +
                    "?ssoToken=" +
                    response.data.getThirdPartyAccessToken.token,
                  "_self"
                );
                this.loading = false;
              }
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.graphQLErrors[0]) {
              this.makeToast("Error", error.graphQLErrors[0]["message"]);
            } else
              this.makeToast("Error", "Something went wrong! Please try again");
          });
      } catch {
        this.loading = false;
        this.makeToast("Error", "Something went wrong! Please try again");
      }
    },
    checkInput(value, destinationElemId) {
      if (Number.isInteger(value)) {
        document.getElementById(destinationElemId).focus();
      }
    },
    limitInput(element, data, max_chars) {
      if (element.value.length > max_chars) {
        data = element.value.substr(0, max_chars);
      }
    },
    focusElement(id) {
      document.getElementById(id).focus();
    },
    validateRedirectUrl(url) {
      if (!url || url === "undefined") {
        this.redirectUrl = null;
        return;
      }

      let newUrl = new URL(url);
      if (
        !newUrl.hostname.includes("goodcreatoracademy.com") &&
        !newUrl.hostname.includes("goodcreator.co") &&
        !newUrl.hostname.includes("creator.goodcreator.co")
      ) {
        this.redirectUrl = null;
      }
      if (
        newUrl.hostname.includes("creator.goodcreator.co") &&
        this.cSource == "flywheel"
      ) {
        this.showWhatsappOptin = true;
      }
    },
  },
  mixins: [webengage],
};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  // min-height: 100vh;
  // height: max-content;
  background-color: #f6fcff;
  // background-color: red;
  border-radius: 30px 0px 0px 0px;

  &-wrapper-1 {
    width: 100%;
    height: 60%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    border-radius: 30px 0 0 0;
  }

  &-wrapper-2 {
    width: 100%;
    height: 30%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0 10px;
  }

  &-wrapper-3 {
    width: 100%;
    height: 50%;
    overflow: hidden;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    border-radius: 0 10px 0 0;
  }

  &-right {
    margin: 40px;
    padding: 50px;
    height: 540px;
    display: flex;
    background-color: white;
    position: relative;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-title {
        font-size: 48px;
        font-weight: 800;
        margin-bottom: 40px;

        &-line {
          border-bottom: 4px solid #01b9f5;
          border-radius: 8px;
          width: 50px;
        }
      }

      &-desc {
        font-size: 18px;
        color: #777;
        margin-bottom: 40px;
      }

      &-desc2 {
        font-size: 12px;
      }

      &-input {
        &-title {
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 0;
        }

        &-field {
          width: 100%;
          border: none;
          outline: none;
          border-bottom: 1px solid #525252;
          font-size: 18px;
          padding: 5px 0;
          font-weight: 400;

          &-square {
            border: none;
            outline: none;
            border: 1px solid #525252;
            font-size: 18px;
            padding: 18px 23px;
            border-radius: 4px;
            margin-right: 10px;
            width: 60px;
            appearance: none;

            &-warning {
              border: 1px solid #ff0000;
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0;
            }
          }

          &-warning {
            border-bottom: 1px solid #ff0000;
          }

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }

          &::-webkit-input-placeholder {
            /* Edge */
            color: #c9c9c9;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #c9c9c9;
          }

          &::placeholder {
            color: #c9c9c9;
          }
        }

        &-warning {
          font-style: italic;
          padding: 10px 0;
          color: #ff0000;
          font-size: 12px;
        }
      }

      &-text-button {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 700;
        color: #01b9f5;
      }
    }

    &-button {
      width: 140px;
      outline: none;
      border: none;
      background-color: #01b9f5;
      color: white;
      border-radius: 10px 0 10px 0;
      font-size: 14px;
      font-weight: 700;
      padding: 10px 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 50px;
      margin-bottom: -20px;
    }
  }
}

@media only screen and (min-width: 766px) {
  .custom-height {
    height: calc(100vh - 114px);
  }

  .custom-padding {
    padding: 0;
  }

  .section {
    &-wrapper-1 {
      &-image {
        height: calc(80vh - 200px);
      }
    }

    &-wrapper-2 {
      &-image {
        height: calc(70vh - 200px);
      }
    }

    &-wrapper-3 {
      &-image {
        height: calc(65vh - 200px);
      }
    }
  }
}

@media only screen and (max-width: 765px) {
  .custom-height {
    display: flex;
    align-items: flex-end;
  }

  .section {
    margin-top: 20px;

    &-wrapper-1 {
      height: 240px;
      width: 195px;
      display: flex;
      justify-content: center;

      &-image {
        // width: 100%;
        height: 292px;
      }
    }

    &-wrapper-2 {
      height: 195px;
      width: 195px;
      display: flex;
      justify-content: center;

      &-image {
        height: 100%;
      }
    }

    &-wrapper-3 {
      height: 220px;
      width: 195px;
      margin-left: 0px;
      display: flex;
      justify-content: center;

      &-image {
        width: 100%;
      }
    }

    &-right {
      margin: 20px;
      padding: 40px;
      // margin-top: -100px;
      margin-bottom: 50px;
      height: 600px;

      &-form-input-field-square {
        padding: 13px 15px;
        width: 45px;
      }

      &-button {
        margin-left: calc(50% - 65px);
      }
    }
  }
}
.customcheck {
  &-checkbox {
  }
  &-label {
    font-weight: 400;
    font-size: 13px;
    color: #565656;
    margin: 0;
  }
}
.disabled-button {
  opacity: 0.7;
  cursor: no-drop;
  pointer-events: none;
}

.center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
