import Index from "@/pages/index";

export const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "*",
    redirect: "/",
  },
];
