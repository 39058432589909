<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div
        class="container d-flex justify-content-start"
        style="padding-left: 7.5px !important"
      >
        <a
          class="navbar-brand"
          href="https://www.goodcreatoracademy.com/"
          style="padding: 0; margin: 0"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2FgoodCreator-logo.png?alt=media&token=f8e5bd93-dd40-4740-a7b8-116920da4607"
            height="50"
            class="d-inline-block align-top"
            alt=""
          />
        </a>

        <!-- <a
          class="navbar-brand"
          href="https://www.goodcreator.co/"
          style="padding: 0; margin: 0"
        >
          <img
            src="@/assets/icons/goodCreator-logo.png"
            height="50"
            class="d-inline-block align-top"
            alt=""
          />
        </a> -->

        <!-- <a class="logo-2" href="https://www.goodcreatoracademy.com/">
          <img
            src="@/assets/icons/logo.png"
            height="50"
            class="d-inline-block align-top"
            alt=""
          />
        </a> -->

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto pl-md-4"></ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Home",
          src: "/",
          key: "index",
        },
        {
          title: "I'm a Creator",
          src: "/creator",
          key: "creator",
        },
        {
          title: "Products & Services",
          src: "#",
          dropdown: true,
        },
      ],
      tools: [
        {
          icon: "https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2Fset2%2FCreator%20search.svg?alt=media&token=635e6a8d-8c13-456b-ac99-eca3f0ef1991",
          title: "Creator Search",
          desc: "The biggest search engine for influencers! Discover thousands of influencers across niches and categories",
          link: "https://winkl.co/discover",
        },
        {
          icon: "https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2Fset2%2FCreator%20insight.svg?alt=media&token=e6e8b647-27a9-41ae-85ea-b3fbf03a77e4",
          title: "Creator Insights",
          desc: "Get insights of any influencer profile within seconds straight from your browser",
          link: "https://chrome.google.com/webstore/detail/influencer-insights-for-i/hcghganppfkplebfebpdnnihcjmjefep",
        },
        {
          icon: "https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2Fset2%2FCampaign%20manager.svg?alt=media&token=73984c5a-ef50-42b9-af9a-573943d67127",
          title: "Campaign Manager",
          desc: "Automated end-to-end campaign execution. Eliminates unnecessary back & forth and countless excel sheets",
          link: "https://campaignmanager.winkl.co/",
        },
        {
          icon: "https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2Fset2%2FBrand%20stability.svg?alt=media&token=c96523a0-17cc-498b-944f-562981609d1b",
          title: "Brand Suitability & Intelligence",
          desc: "Video Marketing With AI-Powered Insights provides valuable digital video insights that helps your brand to create and run successful campaigns",
          link: "https://vidooly.com/brand-intelligence",
        },
        {
          icon: "https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2Fset2%2FMarket%20research.svg?alt=media&token=59fc9670-738e-472c-af8b-c657d7dffdd2",
          title: "Market Research",
          desc: "Enhance your business with our New Age Market Research Solutions and seize opportunities by acting decisively to market change through actionable insights to  drive extraordinary growth",
          link: "https://vidooly.com/market-research",
        },
        {
          icon: "https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2Fset2%2FLeaderboard-103.svg?alt=media&token=8130f139-5d05-4a5b-889e-7ae0127c4a21",
          title: "Leaderboard",
          desc: "Understand insights and data of more than thousands of publishers across the social media platforms",
          link: "https://vidooly.com/leaderboard",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
}

.logo-2 {
  margin-left: 30px;
  border-left: 1px solid #a7a7a7;
  padding-left: 35px;
}

@media only screen and (max-width: 765px) {
  .logo-2 {
    margin-left: 10px;
    padding-left: 20px;
  }
}

.nav-link {
  color: #000 !important;
  &-active {
    font-weight: 700 !important;
  }
}
</style>