<template>
  <section v-if="newSeconds && newSeconds > 0" class="counter">
    {{ fmtMSS(newSeconds) }}
  </section>
</template>

<script>
export default {
  name: "counter",
  components: {},
  props: {
    seconds: {
      default: 0,
    },
  },
  data() {
    return {
      newSeconds: 0,
      intervalId: null,
    };
  },
  created() {
    this.newSeconds = this.seconds;
    if (this.newSeconds) this.startTimer();
  },
  watch: {
    seconds: function (newVal) {
      this.newSeconds = newVal;
      if (newVal) this.startTimer();
    },
  },
  computed: {},
  mounted() {},
  methods: {
    startTimer() {
      var self = this;
      this.intervalId = setInterval(function () {
        self.newSeconds -= 1;
        if (self.newSeconds < 1) {
          self.$emit("counterFinish");
          clearInterval(self.intervalId);
        }
      }, 1000);
    },
    fmtMSS(s) {
      return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss" scoped>
.counter {
  font-size: 14px;
  color: black;
  opacity: 1;
  margin-left: 10px;
  font-weight: 400;
}
</style>