/* eslint-disable no-undef */
export default {
  methods: {
    WebSignUp(payload) {
      webengage.user.login(payload.userID);
      webengage.user.setAttribute("we_phone", payload.phone);
      webengage.track("Web Sign Up", {
        Source: payload.source,
        "User ID": payload.userID,
        Entity: payload.entity,
        g3MemberId: payload.g3MemberId,
      });
    },
    WebLogin(payload) {
      webengage.user.login(payload.userID);
      webengage.track("Web Login", {
        Source: payload.source,
        "User ID": payload.userID,
        Entity: payload.entity,
        g3MemberId: payload.g3MemberId,
      });
    },
    SetUserDetails(payload) {
      webengage.user.setAttribute("we_email", payload.email);
      webengage.user.setAttribute("we_first_name", payload.name);
    },
    WebLoginViewed() {
      let utm = {};
      if (window.sessionStorage.getItem("utmParameters")) {
        utm = JSON.parse(window.sessionStorage.getItem("utmParameters"));
      }
      console.log("login");
      webengage.track("Web Login Viewed", {
        Source: utm.source,
        "User ID": null,
        "Device Id": window.localStorage.getItem("deviceId"),
      });
    },
    WebEnterOTP() {
      let utm = {};
      if (window.sessionStorage.getItem("utmParameters")) {
        utm = JSON.parse(window.sessionStorage.getItem("utmParameters"));
      }
      console.log("otp");
      webengage.track("Web Enter OTP", {
        Source: utm.source,
        "User ID": null,
        "Device Id": window.localStorage.getItem("deviceId"),
      });
    },
    webengageLogin(userId) {
      webengage.user.login(userId);
    },
  },
};
