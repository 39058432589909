import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
// import Cookie from "js-cookie";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
const CLIENT_ID = params.clientId ? params.clientId : "8VX2KOK5GL3ctCqo1d0R"; // TODO: Get from config
const DEVICE_ID = window.localStorage.getItem("deviceId"); // TODO: Get from config
const PORTAL_VERSION = '1.0';

export const CLIENT_HEADERS = {
  "content-type": "application/json",
  accept: "application/json",
  "x-bb-clientid": CLIENT_ID,
  "x-bb-deviceid": DEVICE_ID,
  "x-bb-channelid": "PWA",
  "x-apollo-operation-name": "initDeviceV2",
  "x-bb-version-name": PORTAL_VERSION,
  "x-bb-portal-id": params.portalId || null
};

const authLink = setContext(async ({ operationName }, { headers }) => {
  let token = null;

  // eslint-disable-next-line no-undef
  if ($cookies.isKey("user")) token = $cookies.get("user")["userToken"];
  // eslint-disable-next-line no-undef
  else if (window.sessionStorage.getItem("userToken"))
    // eslint-disable-next-line no-undef
    token = window.sessionStorage.getItem("userToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...CLIENT_HEADERS,
      "x-apollo-operation-name": operationName,
      ...headers,
    },
  };
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "INTERFACE",
          name: "WidgetData",
          possibleTypes: [
            { name: "IconWidgetData" },
            { name: "BannerWidgetData" },
            { name: "ChipItemWidgetData" },
          ],
        },
      ],
    },
  },
});

if (process.env.VUE_APP_ENV == "production")
  var baseDomain = "https://gateway.goodcreator.co/graphql";
else baseDomain = "https://stagegateway.goodcreator.co/graphql";

const client = new ApolloClient({
  link: authLink.concat(
    createHttpLink({
      uri: baseDomain,
      credentials: "same-origin",
    })
  ),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;
